import React from "react"
import Layout from "../components/Layout"
import Socials from "../components/Socials"

export default () => {
  return (
    <Layout>
      <main className="flex flex-col items-center justify-center flex-grow max-w-4xl mx-auto">
        <div className="sm:flex sm:flex-row-reverse sm:items-center">
          <div className="sm:px-2">
            <h1 className="px-4 pt-5 font-serif text-6xl font-bold leading-tight text-left text-primary-dark_gray md:text-center">
              Kay Hoogland
            </h1>
            <hr className="w-2/3 mt-3 ml-4 border-2 border-primary-red md:mx-auto" />
            <p className="px-4 mt-2 text-2xl text-left text-primary-dark_gray font-body md:text-center">
              Machine Learning Engineer
            </p>
            <p className="px-4 mt-2 text-left text-md text-primary-dark_gray font-body md:text-center">
              Based in Rosmalen, The Netherlands
            </p>
          </div>
        </div>

        <Socials />

        <div className="flex w-full">
          <a
            href="mailto:info@kayhoogland.nl"
            className="w-full p-4 mx-4 mt-8 text-lg text-center text-white bg-primary-red rounded shadow hover:bg-red-600"
          >
            Contact me
          </a>
        </div>
      </main>
    </Layout>
  )
}
