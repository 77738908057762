import React from "react"
import { FaGithub, FaTwitter, FaLinkedin, FaInstagram, FaGoodreads } from "react-icons/fa"

const SocialLink = ({ link, children }) => <a class="hover:animate-bounce" rel="noreferrer" target="_blank" href={link}>{children}</a>

const Socials = () => {
  return (
    <div className="flex flex-row w-1/2 mt-8 justify-between">
      <SocialLink link="https://github.com/kayhoogland">
        <FaGithub size={30} className="text-primary-dark_gray" />
      </SocialLink>
      <SocialLink link="https://twitter.com/Kay_Hoogland">
        <FaTwitter size={30} className="text-primary-dark_gray" />
      </SocialLink>
      <SocialLink link="https://www.linkedin.com/in/kay-hoogland/">
        <FaLinkedin size={30} className="text-primary-dark_gray" />
      </SocialLink>
      <SocialLink link="https://www.goodreads.com/user/show/114833463-kay-hoogland">
        <FaGoodreads size={30} className="text-primary-dark_gray" />
      </SocialLink>
      <SocialLink link="https://www.instagram.com/kay_hoog/">
        <FaInstagram size={30} className="text-primary-dark_gray" />
      </SocialLink>
    </div>
  )
}

export default Socials
